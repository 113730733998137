import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import type { RouteState } from "@/__main__/router.mjs";
import { populateMatchHistory } from "@/game-destiny2/fetches/bungie-activities.mjs";
import fetchGuardianMapStats from "@/game-destiny2/fetches/fetch-player-map-stats.mjs";
import fetchGuardianWeaponStats from "@/game-destiny2/fetches/fetch-player-weapon-stats.mjs";
import fetchSeasons from "@/game-destiny2/fetches/fetch-seasons.mjs";
import fetchGuardians from "@/game-destiny2/fetches/guardians-fetch-data.mjs";
import { BungieProfileValidator } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import { MatchValidator } from "@/game-destiny2/models/destiny2-match.mjs";
import { MatchListValidator } from "@/game-destiny2/models/destiny2-match-list.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchData(
  [bungieIdParam, tab]: [string, string],
  searchParams: URLSearchParams,
  state: RouteState,
) {
  try {
    const {
      destiny2: { oauth },
    } = readState;
    const bungieId = Number.isInteger(Number(bungieIdParam))
      ? bungieIdParam
      : oauth[Object.keys(oauth)[0]]?.bungie_uid;

    if (!bungieId) throw new Error("No bungie id");
    populateMatchHistory(bungieId);

    const [profile] = await Promise.all([
      getData(API.getProfile({ bungieId }), BungieProfileValidator, [
        "destiny2",
        "profiles",
        bungieId,
      ]),
      fetchSeasons(),
    ]);

    const seasonId =
      searchParams.get("season") ?? readState.destiny2.seasons[0]?.id;

    await Promise.all([
      // Fetch tab specific data
      (function fetchPageData() {
        switch (tab) {
          case "guardians":
            return fetchGuardians([bungieId]);
          case "maps":
            return (async () => {
              await fetchGuardians([bungieId]);
              if (!seasonId) return;
              await Promise.all(
                Object.keys(readState.destiny2.guardians[bungieId] ?? {}).map(
                  (characterId) =>
                    fetchGuardianMapStats({ characterId, seasonId }),
                ),
              );
            })();
          case "weapons":
            return (async () => {
              if (!profile.blitzUser) return; // Prevent fetching if the user is not a Blitz user
              await fetchGuardians([bungieId]);
              if (!seasonId) return;
              await Promise.all(
                Object.keys(readState.destiny2.guardians[bungieId] ?? {}).map(
                  (characterId) =>
                    fetchGuardianWeaponStats({ characterId, seasonId }),
                ),
              );
            })();
          default:
            return (async () => {
              await Promise.all([
                (async () => {
                  await fetchGuardians([bungieId]);
                  if (!seasonId) return; // Prevent fetching if the user is not a Blitz user
                  const guardianData = Object.keys(
                    readState.destiny2.guardians[bungieId] ?? {},
                  );
                  await Promise.all([
                    guardianData
                      .map((characterId) =>
                        fetchGuardianMapStats({ characterId, seasonId }),
                      )
                      .concat(
                        // @ts-ignore
                        profile.blitzUser
                          ? guardianData.map((characterId) =>
                              fetchGuardianWeaponStats({
                                characterId,
                                seasonId,
                              }),
                            )
                          : [],
                      ),
                  ]);
                })(),
                getData(API.getMatchList({ bungieId }), MatchListValidator, [
                  "destiny2",
                  "matchList",
                  bungieId,
                ]),
              ]);
            })();
        }
      })(),
    ]);

    // Fetch matches (transient)
    await Promise.all(
      Object.entries(state.transient?.visibleMatches ?? {})
        .filter(([_, isVisible]) => isVisible)
        .map(([activityId]) =>
          getData(API.getMatch({ activityId }), MatchValidator, [
            "destiny2",
            "match",
            activityId,
          ]),
        ),
    );
  } catch (e) {
    devError("Failed to fetch profile page data", e);
  }
}
