import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Union } from "@/__main__/data-model.mjs";
import { CLASS } from "@/game-destiny2/constants/class.mjs";

const WeaponStatsModel = {
  class: Union([
    CLASS["2271682572"].beEnum,
    CLASS["3655393761"].beEnum,
    CLASS["671679327"].beEnum,
  ]),
  weaponAggregates: [
    {
      count: Number,
      kills: Number,
      precisionKills: Number,
      weapon: {
        id: String,
        name: String,
        referenceId: Union([String, Number]),
      },
    },
  ],
} satisfies ModelDefinition;

export const GuardianWeaponStatsValidate = createModel({
  data: {
    guardian: WeaponStatsModel,
  },
}).transform(WeaponStatsModel, (response) => response.data.guardian);

export type GuardianWeaponStats = FromModel<typeof GuardianWeaponStatsValidate>;
