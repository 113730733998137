import getData from "@/__main__/get-data.mjs";
import type { GuardianMapStats } from "@/game-destiny2/models/destiny2-player-map-stats.mjs";
import { GuardianMapStatsValidate } from "@/game-destiny2/models/destiny2-player-map-stats.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";

export default function fetchGuardianMapStats(props: {
  characterId: string;
  seasonId: string;
}): Promise<GuardianMapStats> {
  // @ts-ignore Weird, the type is based off the same model :lol:
  return getData(
    API.getPlayerMapStats(props),
    GuardianMapStatsValidate,
    ["destiny2", "mapStats", btoa(props.seasonId + props.characterId)],
    { skipSafetyCheck: true },
  );
}
