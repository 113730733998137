import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Silent, Union } from "@/__main__/data-model.mjs";
import { CLASS } from "@/game-destiny2/constants/class.mjs";

export const guardianMapStatsModel = {
  activity: {
    description: Silent(String),
    id: String,
    mode: {
      id: String,
    },
    name: String,
  },
  assists: Number,
  deaths: Number,
  guardian: {
    class: String,
  },
  id: String,
  kills: Number,
  precisionKills: Number,
  season: {
    id: String,
  },
  secondsPlayed: Number,
  wins: Number,
} satisfies ModelDefinition;

const guardianMapStatsAfterModel = {
  ...guardianMapStatsModel,
  guardian: {
    guardianClass: String,
  },
} satisfies ModelDefinition;

const afterTransformModel = Mapped({
  key: String, // Map name
  value: Mapped({
    key: Union([
      CLASS["2271682572"].beEnum,
      CLASS["3655393761"].beEnum,
      CLASS["671679327"].beEnum,
    ]),
    value: guardianMapStatsAfterModel,
  }),
}) satisfies ModelDefinition;

export const GuardianMapStatsValidate = createModel({
  data: {
    guardian: {
      activityAggregates: [guardianMapStatsModel],
    },
  },
}).transform(afterTransformModel, (response) => {
  const activities = response.data.guardian.activityAggregates;
  // Due to how large this payload is, we don't want to commit all this data to state, we only want the aggregate
  // This will now aggregate data by map and then guardian class
  const result = {};
  for (const activity of activities) {
    const mapName = activity.activity.name;
    const { class: guardianClass } = activity.guardian;
    if (result[mapName]?.[guardianClass]) {
      shallowAggregate(result[mapName][guardianClass], activity);
      continue;
    }
    result[mapName] = {
      [guardianClass]: {
        ...activity,
        guardian: {
          guardianClass,
        },
      },
    };
  }
  return result;
});

export type GuardianMapStats = FromModel<typeof GuardianMapStatsValidate>;

// Utility: Just for this file, its a utility to shallow merge number values (most likely stats) from a target object into a source object, in-place
function shallowAggregate(target: object, source: object): void {
  for (const key in target) {
    const value = source[key];
    if (typeof value !== "number" || typeof target[key] !== "number") continue;
    target[key] += value;
  }
}
