import getData from "@/__main__/get-data.mjs";
import type { GuardianWeaponStats } from "@/game-destiny2/models/destiny2-player-weapon-stats.mjs";
import { GuardianWeaponStatsValidate } from "@/game-destiny2/models/destiny2-player-weapon-stats.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";

export default function fetchGuardianWeaponStats(props: {
  characterId: string;
  seasonId: string;
}): Promise<GuardianWeaponStats> {
  return getData(
    API.getPlayerWeaponStats(props),
    GuardianWeaponStatsValidate,
    ["destiny2", "weaponStats", btoa(props.seasonId + props.characterId)],
    { skipSafetyCheck: true },
  );
}
